import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { HashRouter, useLocation } from "react-router-dom";
import { ApolloProvider } from "react-apollo";
import ApolloClient, { InMemoryCache, IntrospectionFragmentMatcher } from "apollo-boost";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import ReactTooltip, { useRefresh as useTooltipRefresh } from "components/ReactTooltip";
import pkg from "../package.json";
import { Context as EnvironmentContext } from "contexts/Global/env";

import store from "./store";

import GlobalProvider from "contexts/Global";

import { Session } from "./hooks/Utils/Session";
import { UploadFileContextProvider } from "contexts/UploadFile/uploadFileContext";

import AppContent from "./AppContent";
import Support from "./components/Section/Support";
import Playground from "sections/playground";
import NotFound from "components/Section/NotFound";

// CSS styles
import "react-tippy/dist/tippy.css";
import "./main.css";
import "react-toastify/dist/ReactToastify.css";

// ZAFIRO_Support is manually rewritten to index.html in lighttpd.conf
// Any new location must have a corresponding rewrite in the configuration file to avoid a 404 error
const ZAFIRO_SUPPORT = "ZAFIRO_Support";

function App() {
    const features = EnvironmentContext?.features;
    const isCustomPath = [ZAFIRO_SUPPORT].find((path) => document.location.pathname.startsWith(`/${path}`));

    if (isCustomPath === ZAFIRO_SUPPORT) {
        return <Support />;
    }

    if (document.location.pathname != "/") {
        return (
            <div className="bg-white p-10 overflow-auto" style={{ height: "100vh" }}>
                <NotFound />
            </div>
        );
    }

    if (document.location?.hash === "#/version") {
        return <div className="font-mono text-gray-900 p-5">Zafiro Manager v{pkg?.version}</div>;
    }

    // This is a developer playground to test new features
    if (features?.playground && document.location?.hash?.startsWith("#/playground")) {
        return (
            <HashRouter>
                <Provider store={store}>
                    <AppContainer>
                        <div className="bg-gray-200 overflow-auto" style={{ height: "100vh" }}>
                            <Playground />
                        </div>
                    </AppContainer>
                </Provider>
            </HashRouter>
        );
    }
    return (
        <HashRouter>
            <Provider store={store}>
                <AppContainer>
                    <AppContent />
                </AppContainer>
            </Provider>
        </HashRouter>
    );
}

const AppContainer = ({ children }) => {
    useTooltipRefresh();

    const [loading, setLoading] = useState(true);
    const [token, setToken] = useState(null);

    const location = useLocation();

    const TOOLTIP_CONFIG = {
        place: "bottom",
        type: "light",
        offset: { top: -8, left: -8 },
        border: true,
        borderColor: "#D3DAE1",
        className: "max-w-2xl whitespace-prewrap",
    };

    useEffect(() => {
        Session.init();
    }, []);

    const isReady =
        !!token || ["/", "/login"].includes(location.pathname) || location?.pathname?.startsWith("/playground");

    const isUnlogged = !loading && !isReady;

    useEffect(() => {
        if (isUnlogged) {
            document.location.href = "/#/login";
        }
    }, [isUnlogged]);

    return (
        <GQLProvider token={token}>
            <UploadFileContextProvider>
                <GlobalProvider onNewToken={setToken} onLoadingChange={setLoading}>
                    {isReady ? (
                        children
                    ) : (
                        <div className="absolute w-full h-full bg-gray-200 grid place-items-center">
                            <span className="relative flex w-24 h-24 items-center">
                                <div className="absolute h-full w-full rounded-full bg-gray-300 opacity-75 animate-ping"></div>
                                <div className="absolute w-20 h-20 m-2 rounded-full bg-gray-400 opacity-50 animate-ping"></div>
                            </span>
                        </div>
                    )}
                    <ToastContainer
                        position="top-right"
                        className="z-505 mt-24 first-capital"
                        draggable
                        pauseOnHover
                        closeOnClick
                        autoClose={3000}
                    />
                    <ReactTooltip id="default-tooltip" multiline {...TOOLTIP_CONFIG} />
                    <ReactTooltip
                        id="warning-tooltip"
                        multiline
                        {...{
                            ...TOOLTIP_CONFIG,
                            borderColor: "#A85500",
                            backgroundColor: "#EF8500",
                            textColor: "#FFFFFF",
                        }}
                    />
                    <ReactTooltip id="dangerous-html-tooltip" dangerousHtml {...TOOLTIP_CONFIG} />
                </GlobalProvider>
            </UploadFileContextProvider>
        </GQLProvider>
    );
};

const GQLProvider = ({ children, token }) => {
    return <ApolloProvider client={ManagerService(token)}>{children}</ApolloProvider>;
};

export const ManagerService = (token, customURL) => {
    const userToken = token;

    return new ApolloClient({
        uri: customURL || Session.getApiUrl(),
        headers: userToken ? { Authorization: userToken } : null,
        cache: new InMemoryCache({
            fragmentMatcher: new IntrospectionFragmentMatcher({
                introspectionQueryResultData: {
                    __schema: {
                        types: [], // no types provided
                    },
                },
            }),
        }),
        onError: ({ graphQLErrors, operation, response }) => {
            if (!userToken) {
                console.warn("INVALID_CALL: No user token found in this call", {
                    graphQLErrors,
                    operation,
                    response,
                });
            }
            if (graphQLErrors) {
                // If we receive an unauthorized error, we close the session and reload the page
                graphQLErrors.map((e) => {
                    if (e.message.match(/unauthorized/i)) {
                        if (e.path?.includes("getMonitorAvailability")) {
                            //OPTIMIZE this is a temporal fix, we need to investigate why the query is executed automatically sometimes, being a lazyQuery
                            return;
                        }
                        toast.error(e?.message || "Unauthorized response from the server");
                    }
                });
            }
        },
    });
};

export default App;
